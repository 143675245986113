import { css } from '@emotion/core';
import {
  buttons,
  colors,
  fontFamily,
  fontSize,
  fontWeights,
  lineHeight,
  typescale,
  unit,
} from 'styles';

const root = css`
  position: absolute;
  top: 56px;
  right: -3px;
  border-radius: 4px;
  padding: ${unit * 8}px;
  border: 1px solid ${colors.gray};
  background: ${colors.bg};
  display: flex;
  flex-direction: column;
  cursor: default;
  z-index: 1;
  width: 632px;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.1);

  &:after {
    position: absolute;
    top: -6px;
    right: 19px;
    content: ' ';
    width: 10px;
    border-top: 1px solid ${colors.gray};
    border-right: 1px solid ${colors.gray};
    height: 10px;
    background: ${colors.bg};
    transform: rotate(-45deg);
  }
`;

const wrapper = css`
  padding: ${unit * 7}px ${unit * 8}px;
`;

const section = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-bottom: ${unit * 4}px;
`;

const title = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightBold};
  ${typescale.medium};

  color: ${colors.dark};
  opacity: 0.6;
  max-width: 35%;
`;

const infoWrapper = css`
  max-width: 60%;
  background-color: rgb(232, 237, 250);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const info = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;

  ${typescale.small};

  color: ${colors.info};
  padding: ${unit * 2}px ${unit * 5}px;
`;

const gridWrapper = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${unit}px ${unit * 5}px;

  padding: ${unit * 3}px 0;
`;

const extendedColumn = css`
  grid-column-start: span 2;
`;

const extendedColumnTo3 = css`
  grid-column-start: span 3;
`;

const fullWidth = css`
  grid-column-start: span 4;
`;

const contentTitle = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;
  font-size: 10px;
  line-height: 12px;

  color: ${colors.dark};
  opacity: 0.4;
  text-transform: uppercase;
`;

const text = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;
  ${typescale.base};

  color: ${colors.dark};
`;

const priceWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const closeWrapper = css`
  align-items: flex-start;
  svg {
    transition: all 0.5s ease-out;
    cursor: pointer;

    &:hover {
      g  {
        opacity: 1;
      }
      #hover {
        fill: ${colors.primary};
        stroke: ${colors.bg};
      }
    }
  }
`;

const line = css`
  width: 100%;
  height: 1px;
  background-color: ${colors.dark};
  opacity: 0.1;
  margin-top: ${unit * 2}px;
`;

const taxesText = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-size: ${fontSize.base}px;
  line-height: ${lineHeight.base}px;

  color: ${colors.dark};
  opacity: 0.6;
`;

const noOpacity = css`
  opacity: 1;
`;

const boldText = css`
  font-weight: ${fontWeights.weightBold};
`;

const taxesPadding = css`
  padding-top: ${unit * 4}px;
  padding-bottom: ${unit * 6}px;
`;

const noWrap = css`
  white-space: nowrap;
`;

const content = css`
  overflow-y: auto;
  max-height: 400px;

  padding-bottom: ${unit * 3}px;

  &:before {
    content: '';
    width: 100%;
    height: 330px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(transparent 150px, white);
  }
`;

const contentTop = css`
  position: 'relative';
`;

const buttonDelete = css`
  ${buttons.teritary.medium}
  width: 45%;
  display: inline-block;
`;

const buttonAdd = css`
  ${buttons.primary.medium}
  width: 45%;
  display: inline-block;
`;

const buttonWrapper = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const cartPopup = {
  root,
  wrapper,
  section,
  title,
  text,
  infoWrapper,
  info,
  gridWrapper,
  extendedColumn,
  extendedColumnTo3,
  contentTitle,
  priceWrapper,
  closeWrapper,
  line,
  taxesText,
  noOpacity,
  boldText,
  fullWidth,
  taxesPadding,
  noWrap,
  content,
  contentTop,
  buttonAdd,
  buttonDelete,
  buttonWrapper,
};
